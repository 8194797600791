exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-codefaq-js": () => import("./../../../src/pages/codefaq.js" /* webpackChunkName: "component---src-pages-codefaq-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-educators-js": () => import("./../../../src/pages/educators.js" /* webpackChunkName: "component---src-pages-educators-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-pricing-ondemand-js": () => import("./../../../src/pages/pricing-ondemand.js" /* webpackChunkName: "component---src-pages-pricing-ondemand-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-recruiters-js": () => import("./../../../src/pages/recruiters.js" /* webpackChunkName: "component---src-pages-recruiters-js" */),
  "component---src-pages-refund-js": () => import("./../../../src/pages/refund.js" /* webpackChunkName: "component---src-pages-refund-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-tour-create-test-js": () => import("./../../../src/pages/tour/create-test.js" /* webpackChunkName: "component---src-pages-tour-create-test-js" */),
  "component---src-pages-tour-index-js": () => import("./../../../src/pages/tour/index.js" /* webpackChunkName: "component---src-pages-tour-index-js" */),
  "component---src-pages-tour-manage-candidates-js": () => import("./../../../src/pages/tour/manage-candidates.js" /* webpackChunkName: "component---src-pages-tour-manage-candidates-js" */),
  "component---src-pages-tour-manage-questions-js": () => import("./../../../src/pages/tour/manage-questions.js" /* webpackChunkName: "component---src-pages-tour-manage-questions-js" */),
  "component---src-pages-tour-privacy-and-security-js": () => import("./../../../src/pages/tour/privacy-and-security.js" /* webpackChunkName: "component---src-pages-tour-privacy-and-security-js" */),
  "component---src-pages-tour-programming-test-js": () => import("./../../../src/pages/tour/programming-test.js" /* webpackChunkName: "component---src-pages-tour-programming-test-js" */),
  "component---src-pages-tour-questionnaire-test-js": () => import("./../../../src/pages/tour/questionnaire-test.js" /* webpackChunkName: "component---src-pages-tour-questionnaire-test-js" */),
  "component---src-pages-tour-test-center-js": () => import("./../../../src/pages/tour/test-center.js" /* webpackChunkName: "component---src-pages-tour-test-center-js" */),
  "component---src-pages-tour-test-result-js": () => import("./../../../src/pages/tour/test-result.js" /* webpackChunkName: "component---src-pages-tour-test-result-js" */),
  "component---src-pages-trainers-js": () => import("./../../../src/pages/trainers.js" /* webpackChunkName: "component---src-pages-trainers-js" */),
  "component---src-pages-typingtest-js": () => import("./../../../src/pages/typingtest.js" /* webpackChunkName: "component---src-pages-typingtest-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blogList.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */)
}

